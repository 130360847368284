import React from "react";
import PageHeader from "../components/general-components/page-header.component";
import Faq from "../components/faq-components/faq-component";

const FaqPage = () => {

  return (
    <div>
      <PageHeader headertitle="FAQs" />
      <Faq />
    </div>
  );
};

export default FaqPage;
