import React from "react";
import AllTours from "../components/all-tours-components/all-tours.component";
import PageHeader from "../components/general-components/page-header.component";

const AllToursPage = () => {
  return (
    <div>
      <PageHeader headertitle="Tours" />
      <AllTours />
    </div>
  );
};

export default AllToursPage;
