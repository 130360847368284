import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AllToursPage from "./pages/all-tours";
import FaqPage from "./pages/faq-page";

const Home = lazy(() => import("./pages/home-page"));
const AboutPage = lazy(() => import("./pages/about-page"));
const ContactPage = lazy(() => import("./pages/contact-page"));
const TourDetailsPage = lazy(() => import("./pages/tour-details"));
const TourListPage = lazy(() => import("./pages/tour-list"));
const Error = lazy(() => import("./pages/404"));
const PrivacyPage = lazy(() => import("./pages/privacy-policy-page"));
const CancellationPage = lazy(() => import("./pages/cancellation-page"));
const Navbar = lazy(() =>
  import("./components/general-components/navbar.component")
);
const Footer = lazy(() =>
  import("./components/general-components/footer.component.jsx")
);
const TailorMade = lazy(() => import("./pages/tailor-make"));

const renderLoader = () => (
  <div className="preloader" id="preloader">
    <div className="preloader-inner">
      <div className="spinner">
        <div className="dot1"></div>
        <div className="dot2"></div>
      </div>
    </div>
  </div>
);

class App extends Component {
  render() {
    return (
      <Suspense fallback={renderLoader()}>
        <Router basename="/">
          <div>
            <Navbar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={AboutPage} />
              <Route path="/privacy-policy" component={PrivacyPage} />
              <Route path="/cancellation-policy" component={CancellationPage} />
              <Route path="/contact" component={ContactPage} />
              <Route path="/tours" component={AllToursPage} />
              <Route path="/tour-list" component={TourListPage} />
              <Route path="/tour-details/" component={TourDetailsPage} />
              <Route path="/tailor-make" component={TailorMade} />
              <Route path="/faq-page" component={FaqPage} />
              <Route path="/404" component={Error} />
              <Route component={Error} />
            </Switch>
            <Footer />
          </div>
        </Router>
      </Suspense>
    );
  }
}

export default App;
