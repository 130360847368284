import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const AllTours = () => {
 
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="destination-list-area pd-top-120">
      <div className="container">
        {/* destination-list gallery start */}
        <div className="gallery-area destination-list-gallery-area">
          <div className="container">
            <div className="gallery-filter-area row">
              {/* gallery-item */}
              <div className="tp-gallery-item col-lg-6 col-md-6 col-12 mb-10">
                <div className="tp-gallery-item-img">
                  <div className="thumb">
                    <img
                      src={publicUrl + "assets/img/banner/1.jpg"}
                      alt="image"
                    />
                  </div>
                  <div className="details">
                    <h3>Packages</h3>
                    <p>
                      Etiam convallis elementum sapien, aliquam turpis aliquam
                      vitae Praese sollicitudin <br /> felis vel mi facilisis
                      posuere. Nulla ultrices facilisis
                    </p>
                    <Link className="btn-read-more" to="/tour-list">
                      <span>
                        Explore
                        <i className="la la-arrow-right" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* gallery-item */}
              <div className="tp-gallery-item col-lg-6 col-md-6 col-12 mb-10">
                <div className="tp-gallery-item-img">
                  <div className="thumb">
                    <img
                      src={publicUrl + "assets/img/banner/2.jpg"}
                      alt="image"
                    />
                  </div>
                  <div className="details">
                    <h3>Day Trips</h3>
                    <p>
                      Etiam convallis elementum sapien, aliquam turpis aliquam
                      vitae Praese sollicitudin <br /> felis vel mi facilisis
                      posuere. Nulla ultrices facilisis
                    </p>
                    <Link className="btn-read-more" to="/tour-list">
                      <span>
                        Explore
                        <i className="la la-arrow-right" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Gallery area end */}
      </div>
    </div>
  );
};

export default AllTours;
